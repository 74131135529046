<template>
	<div v-if="$api.texts['order-online-creation-error']">
		<Order state="error">
			<div v-html="$api.texts['order-online-creation-error'].raw"></div>
		</Order>
	</div>
</template>

<script>
	import Order from "../Order";

	export default {
		name: "OrderOnlineError",
		metaInfo: {
			title: "Chyba platební brány",
			meta: [{
				name: "robots",
				content: "noindex"
			}]
		},
		components: {Order}
	}
</script>